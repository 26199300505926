.Tasks {
  width: 400px;
  height: 300px;
  overflow: hidden;
}

.Tasks .Tasks-box {
  background: transparent;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
  transition: opacity 0.3s;
}

.Task {
  width: 400px;
  background: rgb(255, 255, 255);
  margin-bottom: 5px;
  border-radius: 10px;
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #666;
  cursor: grab;
}

.Task input {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  border: none;
  outline: none;
}
.Task span {
  display: inline-block;
  font-family: Jost, sans-serif;
  font-weight: 500;
  background-color: #f4f4f4;
  border: 2px solid ;
  border-radius: 20px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px #fff;
  color: #333;
  margin: 0 0.15em;
  font-size: 11px;
  padding: 0.25em 0.7em;
  white-space: nowrap;
  width: 70px;
  text-align: center;
  cursor: pointer;
}
